import axios from 'axios';
import { getCookie } from '@/utils/Utils';
/**
 * @author CHOI DAE GEON
 * @param {String} spaceId
 * @param {String} userId
 * @param {String} musicPath
 * @param {Object} headers
 * @returns Promise
 */
const checkLike = async (spaceId, userId, musicPath, headers) => {
  return axios.post('/api/player/selectMusicLikeUnlike', { spaceId, userId, musicPath }, { headers });
};

/**
 * @author CHOI DAE GEON
 * @param {String} channelId
 * @param {String} headers
 * @returns Promise
 */
const getChannelMusicInfo = async (channelId, headers) => {
  return channelId !== '' ? await axios.post('/api/player/getChannelMusicInfo', { channelId }, { headers }) : () => {};
};

/**
 *
 * @param {Object} params
 * @returns Promise
 */
const setLikeMusic = async (params = { spaceId: '', musicPath: '', like: '', weatherCode: '', channelId: '' }) => {
  return await axios.post('/api/player/setLikeMusic', params);
};

/**
 *
 * @param {String} spaceId
 * @param {Object} headers
 * @returns
 */
const getSelectRecommend = async spaceId => {
  return await axios.post('/api/player/selectRecommendedChannels', { spaceId });
};

/**
 *
 * @param {String} channelId
 * @returns
 */
const getChannelInfo = async (channelId, spaceId = null) => {
  return await axios.post('/api/player/getChannelInfo', { channelId, spaceId });
};

/**
 *
 * @param {String} spaceId
 * @returns
 */
const selectFavoriteMusicList = async spaceId => {
  return await axios.post('/api/player/selectFavoriteMusicList', { spaceId });
};

/**
 *
 * @param {String} userId
 * @param {String} spaceId
 * @param {String} favoriteMusicList
 * @param {Boolean} myChannelYn
 * @returns
 */
const updateFavoriteMusicListForZeroOne = async (userId, spaceId, favoriteMusicList, myChannelYn) => {
  return await axios.post('/api/player/updateFavoriteMusicListForZeroOne', {
    userId,
    spaceId,
    favoriteMusicList,
    myChannelYn
  });
};

/**
 *
 * @param {*} spaceId
 * @param {*} lon
 * @param {*} lat
 * @param {*} weather
 * @param {*} time
 * @returns
 */
const getPlayerInfo = async (spaceId, lon, lat, weather, time) => {
  return await axios.post(`/api/player/getPlayerInfo`, { spaceId, lon, lat, weather, time });
};
/**
 *
 * @param {*} spaceId
 * @returns Promise
 */
const selectSimilarChannels = async spaceId => {
  return spaceId ? await axios.post(`/api/player/selectSimilarChannels`, { spaceId }) : Promise.resolve(true);
};

/**
 *
 * @param {*} spaceId
 * @returns
 */
const selectPopularityChannels = async spaceId => {
  return await axios.post(`/api/player/selectPopularityChannels`, { spaceId });
};

/**
 *
 * @param {*} spaceId
 * @returns
 */
const selectDesignatedRecommendedChannels = async spaceId => {
  return await axios.post(`/api/player/selectDesignatedRecommendedChannels`, { spaceId });
};

/**
 *
 * @param {*} spaceId
 * @returns
 */
const selectBookmarkChannels = async spaceId => {
  return await axios.post(`/api/player/selectBookmarkChannels`, { spaceId });
};

/**
 *
 * @param {*} spaceId
 * @param {*} recomId
 * @returns
 */
const insertBookmarkChannel = async (spaceId, recomId) => {
  return await axios.post(`/api/player/insertBookmarkChannel`, { spaceId, recomId });
};
/**
 *
 * @param {*} spaceId
 * @param {*} recomId
 * @returns
 */
const deleteBookmarkChannel = async (spaceId, recomId) => {
  return await axios.post(`/api/player/deleteBookmarkChannel`, { spaceId, recomId });
};

/**
 *
 * @param {*} spaceId
 * @returns Promise
 */
const getBrandSpaceMusicList = async spaceId => {
  return await axios.post(`/api/player/getBrandSpaceMusicList`, { spaceId });
};
/**
 *
 * @param {*} spaceId
 * @returns Promise
 */
const getMySoundeMusicList = async spaceId => {
  return await axios.post(`/api/player/getMySoundeMusicList`, { spaceId });
};

/**
 *
 * @param {*} spaceId
 * @returns Promise
 */
const getCurationInfo = async spaceId => {
  return await axios.post(`/api/profile/getCurationInfo`, { spaceId });
};

/**
 * @description 인기채널,이런채널어때요
 * @param {*} spaceId
 * @returns Promise
 */
const selectAllChannel = async spaceId => {
  return await axios.post(`/api/player/selectAllDesignatedRecommendedChannels`, {
    spaceId
  });
};

/**
 * @description 뮤직리스트 조회
 * @param {String} spaceId
 * @param {String} timezone
 * @param {String} email
 * @param {String} date
 * @returns Promise
 */
const getMusicList = async (spaceId, timezone, email, date) => {
  return await axios.post('/api/player/getMusicList', { spaceId, timezone, email, date });
};

/**
 * @description 소켓연결된 스페이스 연결상태확인
 * @param {*} spaceId
 * @returns Promise
 */
const getBldgState = async (spaceId) => {
  const userId = getCookie("userId");
  return await axios.post(`/api/player/getBldgState`, {
    spaceId,
    userId
  });
};

/**
 * @description 소켓연결된 스페이스 재생
 * @param {*} spaceId
 * @returns Promise
 */
const setBldgPlay = async (spaceId, userId) => {
  return await axios.post(`/api/player/setBldgPlay`, {
    spaceId,
    userId
  });
};

/**
 * @description 소켓연결된 스페이스 중지
 * @param {*} spaceId
 * @returns Promise
 */
const setBldgStop = async (spaceId, userId) => {
  return await axios.post(`/api/player/setBldgStop`, {
    spaceId,
    userId
  });
};

/**
 * @description 볼륨만 조정할 때는 playVolume 필요, 음소거 설정/해제 시에는 playVolume, prePlayVolume, isMuteYn 필요
 * @param {*} params
 * @returns Promise
 */
const setBldgVolume = async (params) => {
  return await axios.post(`/api/player/setBldgVolume`, params);
};

export {
  checkLike,
  getChannelMusicInfo,
  setLikeMusic,
  getSelectRecommend,
  getChannelInfo,
  selectFavoriteMusicList,
  updateFavoriteMusicListForZeroOne,
  getPlayerInfo,
  selectSimilarChannels,
  selectPopularityChannels,
  selectDesignatedRecommendedChannels,
  selectBookmarkChannels,
  insertBookmarkChannel,
  deleteBookmarkChannel,
  getBrandSpaceMusicList,
  getMySoundeMusicList,
  getCurationInfo,
  selectAllChannel,
  getMusicList,
  getBldgState,
  setBldgPlay,
  setBldgStop,
  setBldgVolume
};
